import React from "react"
import { useColorMode } from "theme-ui"
import {
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Container from "../../../../../components/container"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import KarteReise from "../../../../../images/kurse/karte-reise.svg"
import KarteReiseLight from "../../../../../images/kurse/karte-reise-light.svg"
import Circle from "../../../../../components/circle"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "reisetagebuch",
}

const Page = () => {
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)
  const [colorMode] = useColorMode()

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/der-rhein" />
      }
      navigation={navigation}
      progress={progress}
    >
      <Seo title="Orte markieren" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Orte markieren
          </Heading>
          <Paragraph>
            Damit hast du Hölderlins Reiseroute aufgezeichnet: Er startete am
            Kloster Maulbronn <Circle size="sm">1</Circle>. Dann ging die Reise
            über Bruchsaal <Circle size="sm">2</Circle> nach Rheinhausen{" "}
            <Circle size="sm">3</Circle>, wo er das erste Mal auf seiner Reise
            den Rhein überquerte. Nach Zwischenstopps in Mannheim{" "}
            <Circle size="sm">4</Circle>, Frankenthal{" "}
            <Circle size="sm">5</Circle> und Speyer <Circle size="sm">6</Circle>{" "}
            landete er abermals in Rheinhausen und ritt mit dem Pferd zurück
            nach Maulbronn.
          </Paragraph>
          <Container maxWidth="lg">
            <img
              src={colorMode === "light" ? KarteReiseLight : KarteReise}
              alt="Stilisierte Landkarte mit allen Hölderlin-Orten"
              sx={{ width: "100%" }}
            />
          </Container>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
